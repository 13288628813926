import { STERILIZATION_SPECIFICATIONS_CONTAINERSIZES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Container Size" } }, [_c("select-input", { key: "facility", attrs: { "data-source": _vm.facilities, "default-active-first-option": "", "label": "Facility", "on-select-change": _vm.onFacilitySelect, "rules": "required", "source": "code", "span": 24 } }), _c("select-input", { key: "type", attrs: { "reference": "sterilization.common.container-types", "source": "name", "label": "Container Type", "span": 12, "rules": "required" } }), _c("text-input", { key: "name", attrs: { "label": "Container Size", "placeholder": "Type Container Size", "max-length": 20, "form-item": "", "rules": "required", "span": 12 } }), _c("select-input", { key: "fillingLines", attrs: { "delete-filter": "IsInactive", "label": "Line", "lazy": "", "mode": "multiple", "queries": _vm.fillingLinesQueries, "reference": "sterilization.common.filling-lines", "rules": "required", "search-by": "Description", "source": "id", "source-label": "description", "span": 24 } }), _c("text-input", { key: "description", attrs: { "label": "Nominal Description", "placeholder": "Nominal Description", "max-length": 50, "form-item": "", "rules": "required", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateContainerSizeModal",
  inject: ["apiUrl"],
  data() {
    return {
      selectedFacility: "PK",
      facilities: [{ code: "PK" }, { code: "PP" }, { code: "PT" }]
    };
  },
  computed: {
    fillingLinesQueries() {
      return [["facility", this.selectedFacility]];
    }
  },
  methods: {
    onFacilitySelect(value) {
      this.selectedFacility = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateContainerSizes = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-container-sizes" }, [_c("resource", { attrs: { "name": "sterilization.common.filling-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.can-sizes", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/container-sizes" } }, [_c("create-container-sizes")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateContainerSizes
  },
  data() {
    return {
      apiUrl,
      page: STERILIZATION_SPECIFICATIONS_CONTAINERSIZES
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
